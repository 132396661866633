
import { defineComponent } from "vue";
// @ts-expect-error
import { selectOption } from "hestia";
import { Tooltip } from "@progress/kendo-popups-vue-wrapper";

export default defineComponent({
  name: "IncentiveProgramSelect",
  components: {
    selectOption,
    Tooltip,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: Number,
    distributorCode: String,
    countryCode: String
  },
  inject: ["api"],
  data() {
    return {
      incentiveTooltipContent:
        "<p class='incentive-program-tooltip'>Available programs will be loaded after Distributor is selected.</p>",
      isAnyIncentivePrograms: true,
    };
  },
  computed: {
    selectKey(): string {
      return (this.distributorCode || "") + (this.countryCode || "");
    },
  },
  methods: {
    async populateIncentiveProgramsByDistributor() {
      if (!this.distributorCode) {
        return Promise.resolve([]);
      }

      const programs = await this.api.getIncentivePrograms(this.distributorCode, this.countryCode);

      // mark if we have any incentivePrograms
      if (programs?.length) {
        this.isAnyIncentivePrograms = true;
      } else {
        this.isAnyIncentivePrograms = false;
      }

      return programs;
    },
  },
  watch: {
    distributorCode: {
      handler() {
        this.$emit("update:modelValue", null);
      },
    },
    countryCode: {
      handler() {
        this.$emit("update:modelValue", null);
      },
    }
  },
});
