<template>

      <div class="content">
        <div class="columns is-multiline are-thirds">

          <form-select v-model="model.distributorCode" label="Distributor" :populate-fn="populateDistributors()" use-code required />

          <sales-rep-field :countryCode="model.countryCode" :postalCode="model.postalCode" v-model="model.salesRepCode" />

          <form-text label="Requestor" :text="requestor" />

          <form-auto-complete v-model="model.customerName" label="Customer" :query-fn="queryCustomer" required name-as-value />

          <form-input v-model="model.applicationDetail" label="Application Detail" required />

          <form-input v-model="model.projectName" label="Program / Project Name" name="projectName" />

          <form-select v-model="model.countryCode" label="Country" :populate-fn="populateCountries()" required use-code />

          <form-input v-model="model.state" label="State" />

          <form-input v-model="model.postalCode" label="Postal Code" :required="requirePostalCode()" />

          <form-auto-complete :modelValue="model.part" label="Part" :query-fn="queryPart" :max-length="50" required name-as-value @update:modelValue="updatePart($event)" @selected="selectedPart = $event" />

          <registration-type-field :part="model.part" :is-design-registration-part="selectedPart?.isDesignatedForDesignRegistration" :registration-type-id="model.registrationTypeId" ></registration-type-field>

          <IncentiveProgramSelect v-model="model.incentiveProgramId" :distributorCode="model.distributorCode" :countryCode="model.countryCode" />

          <form-select v-model="model.marketGroupCode" label="Market" :populate-fn="populateMarketGroups()" required use-code />

          <input-prev-reg :prevReg="prevReg" :prevRegistrationCriteria="prevRegistrationPM" @update="onPrevRegUpdate($event)" />
          
          <form-input v-model="model.distributorInternalReference" label="Distributor Internal Reference" />        
        </div>

        <div class="columns are-one-fifth">

          <form-field label="Start of Production" required :validation-model="model.startOfProduction || null">
            <date-picker :modelValue="model.startOfProduction" @update:modelValue="model.startOfProduction = $event" name="startOfProduction"></date-picker>
          </form-field>

          <form-field :min-value="1" label="Estimated Annual Units" required name="estimatedAnnualUnits" :validation-model="model.estimatedAnnualUnits || 0"
          :validation-fn="validateRegMinimumRevenue">
            <numeric-text-box
                v-model="model.estimatedAnnualUnits"
                :defaultValue="0"
                :min="1"
                :format="qtyFormat"
                :spinners="false"
                :step="1"
                @focus="selectContent"
                @change="handleQtyShortcuts($event)"
                placeholder="EAU" 
                name="estimatedAnnualUnits"
              ></numeric-text-box>
          </form-field>

          <form-field :min-value="0.0001" label="Target Distribution Buy Price" name="targetBuyPrice" required :validation-model="model.targetBuyPrice || 0"
          :validation-fn="validateRegMinimumRevenue">
            <div class="control has-icons-left">
              <numeric-text-box
                :value="model.targetBuyPrice"
                @update:modelValue="model.targetBuyPrice = $event"
                :defaultValue="0"
                :min="0.0001"
                :format="priceFormat"
                :spinners="false"
                :step="0.0001"
                @focus="selectContent"
                placeholder="Target Distribution Buy Price"
                name="targetBuyPrice"
                ></numeric-text-box>
              <span class="icon is-left">
                <i class="fas fa-dollar-sign" />
              </span>
            </div>
          </form-field>

          <form-field :min-value="0.0001" label="Target Resale Price" name="targetResalePrice" required :validation-model="model.targetResalePrice || 0">
            <div class="control has-icons-left">
              <numeric-text-box
                :value="model.targetResalePrice"
                @update:modelValue="model.targetResalePrice = $event"
                :defaultValue="0"
                :min="0.0001"
                :format="priceFormat"
                :spinners="false"
                :step="0.0001"
                @focus="selectContent"
                placeholder="Target Resale Price"
                name="targetResalePrice"
                ></numeric-text-box>
              <span class="icon is-left">
                <i class="fas fa-dollar-sign" />
              </span>
            </div>
          </form-field>

          <distributor-book-cost-field 
            :part="model.part" 
            :distributorCode="model.distributorCode">
          </distributor-book-cost-field>
        </div>

        <div class="columns is-multiline">
          <div class="is-four-fifths">
            <form-field label="Comments">
              <textarea v-model="model.comments" name="comments"></textarea>
            </form-field>
          </div>

          <div class="is-one-fifth">
            <form-text label="Expiration Date" text="1 year from approval" />
          </div>

          <div class="is-full">
            <div class="field">
              <label class="label is-required">Supporting Documents</label>
              <div class="file is-fullwidth is-vertical">
                  <file-upload 
                    name="docs"
                    :loading="isFileDownloading"
                    :async-save-url="filesUri()"
                    :async-remove-url="filesUri()"
                    :files="model.docs || []"
                    :allowed-extensions="allowedFileExtensions"
                    @delete="onFileDeleted"
                    @error="onFileError"
                    @update:files="$emit('update:files', $event)"
                    @success="$emit('fileUploaded', $event)"
                    @update:loading="$emit('uploading', $event)"
                    @download="downloadFile" />
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import '@progress/kendo-ui/js/kendo.upload'
import { formComponents as hestiaFormComponents, utils, fileUpload } from 'hestia'
import { inputPrevReg } from '@/components'
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import { NumericTextBox } from '@progress/kendo-vue-inputs';
import { PreviousRegistrationPM, PreviousRegistration } from '@/models';
import DistributorBookCostField from "./distributor-book-cost-field.vue";
import RegistrationTypeField from "./registration-type-field.vue";
import IncentiveProgramSelect from "./incentive-program-select.vue";
import { DistributorSettingsService } from "@/services";
import { downloadFile } from "@/helpers";
import SalesRepField from "./sales-rep-field.vue";


const apiFunctions = {
  populateCountries() {
    return this.api.getCountries();
  },
  populateDistributors() {
    return this.api.getDistributors();
  },
  populateMarketGroups() {
    return this.api.getMarketGroups();
  },
  queryCustomer(val) {
    return this.api.lookupCustomers(val);
  },
  queryPart(val) {
    return this.api.lookupParts(val);
  },
  filesUri() {
    return this.api._filesUri;
  }
}

export default {  
  name: "EditForm",
  components: {
    inputPrevReg,
    DatePicker,
    NumericTextBox,
    DistributorBookCostField,
    RegistrationTypeField,
    IncentiveProgramSelect,
    SalesRepField,
    fileUpload,
    ...hestiaFormComponents,
  },
  inject: ['api', 'userAuth'],
  emits: ["fileDeleted", "fileUploaded", "update:files", "update:modelValue", "uploading"],
  props: {
    modelValue: {
      type: Object,
      required: false
    },
    documents: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      model: {},
      priceFormat: {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4
      },
      qtyFormat: {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      },
      selectedPart: null,
      regMinimumRevenue: 0,
      distroSettingService: new DistributorSettingsService(this.api),
      isFileDownloading: false,
      allowedFileExtensions: []
    };
  },
  computed: {
    requestor() {
      return this.$store.state['auth'].name;
    },
    prevRegistrationPM() {
      return new PreviousRegistrationPM(this.model.distributorCode, this.model.customerName, this.model.part);
    },
    prevReg() {
      return new PreviousRegistration(this.model?.prevRegistrationId, this.model?.prevRegistrationNumber);
    },
    isAllegroUser() {
      return this.userAuth.isAllegro;
    }
  },
  methods: {
    ...apiFunctions,
    requirePostalCode() {
      const country = this.model.countryCode;
      return country === 'USA' || country === "CAN";
    },
    onFileDeleted({ file, response }) {
      const rowVersion = response?.rowVersion;
      this.$emit("fileDeleted", { file, rowVersion });
    },
    onFileError(error) {
      const errors = error?.errors ?? { error: error.message };
      utils.keys(errors).forEach(key => {
        const message = `${key}: ${errors[key]}`;
        this.$error({ message });
      });
    },
    validateRegMinimumRevenue() {
      const amount = this.model.estimatedAnnualUnits;
      const price = this.model.targetBuyPrice;
      const minimum = this.regMinimumRevenue;
    
      // if don't have a minimum or price or amount is udefined, then return as a valid.
      if (!minimum || price == undefined || amount == undefined) {
        return true;
      }

      // return error message, if minimum is not met
      if (amount * price < minimum) {
        return `Estimated revenue (estimated annual units * target buy price) lower than the minimum required: $${minimum}.`;
      }

      return true;
    },
    handleQtyShortcuts(event) {
      const inputEl = event?.event?.target;
      const found = inputEl?.value?.match(/^.*([km])$/i);
      if(!found) return;
      
      const val = event.value;
      const multiplier = found[1].toLowerCase();
      const multiplierVal = multiplier === "k" ? 1000 : multiplier === "m" ? 1000_000 : undefined;
      if(!multiplierVal) return;

      this.$nextTick(() => {
          this.model.estimatedAnnualUnits = val * multiplierVal;
          this.$nextTick(() => inputEl.value = this.model.estimatedAnnualUnits);
      });
    },
    onPrevRegUpdate(prevReg) {
      this.model.prevRegistrationId = prevReg.registrationId;
      // make sure that we don't store prevRegistrationNumber in json, 
      // since prevRegistrationNumber can change over the time
      this.model.prevRegistrationNumber = undefined;
    },
    updatePart(value) {
      this.model.part = value;
      // Clear selected part on user input - part has changed
      this.selectedPart = null;
      // Clear RegistrationType on part input - is only to show after initial registration load
      this.model.registrationTypeId = null;
    },
    async getDesignRegMinimumRevenue(distributorCode) {
      this.regMinimumRevenue = await this.distroSettingService.getDistributorRevenueMinimum(distributorCode);
    },
    async downloadFile(file) {
      let response;
      try {
        this.isFileDownloading = true;
        response = await this.api.getFileResponse(file.fileName, file.referenceName);
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.isFileDownloading = false;
      }

      const blob = await response.blob();
      downloadFile(blob, file.fileName);
    },
    selectContent($event) {
      this.$nextTick(
        () => $event.target?.select()
      );
    },
    async loadFileExtensions() {
      this.allowedFileExtensions = await this.api.getAllowedFileExtensions();
    }
  },
  mounted() {
    // if we have distributorCode, get the revenueMinimum for this distributor
    if (this.model.distributorCode) {
      this.getDesignRegMinimumRevenue(this.model.distributorCode);
    }
    this.loadFileExtensions();
  },
  watch: {
    model: {
      handler() {
        this.$emit("update:modelValue", this.model);
      },
      deep: true
    },
    modelValue: {
      handler() {
        this.model = this.modelValue;
      },
      immediate: true
    },
    selectedPart() {
        this.model.registrationTypeId = null;
    },
    "model.distributorCode": {
      handler(newValue, oldValue) {
        if(newValue !== oldValue) this.model.incentiveProgramId = null;
        this.getDesignRegMinimumRevenue(newValue);
      }
    }
  }
};
</script>

<style scoped>
.columns.are-thirds > * {
  flex: none;
}

.columns.are-one-fifth > * {
  flex: none;
  width: 20%;
}

.k-widget.k-datepicker, .k-widget.k-numerictextbox {
  width: 100%;
  margin-top: -2px;
}

.control.has-icons-left span.icon.is-left {
  /* Telerik icon color */
  color: #656565;
}
</style>

<style>
.k-numerictextbox .k-numeric-wrap {
  border: none;  
}
</style>
