
import { defineComponent } from "vue";
import { NumericTextBox } from '@progress/kendo-vue-inputs';
import { Tooltip } from "@progress/kendo-popups-vue-wrapper";
// @ts-expect-error 
import { loading } from "aml";

export default defineComponent({
    name: "DistributorBookCostField",
    components: {
        NumericTextBox,
        Tooltip,
        loading
    },
    props: {
        part: String,
        distributorCode: String
    },
    inject: ['api'],
    data() {
        return {
            priceFormat: {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4
            },
            tooltipContent: "<p class=\"cost-tooltip\">Price is loaded when valid Distributor and Part is selected.</p>",
            isLoading: false,
            distributorBookCost: 0
        }
    },
    computed: {
        canLoadCost(): boolean {
            // TODO: add validation for part?
            return !!(this.part && this.part.length >= 3 && this.distributorCode && this.distributorCode.length >= 3);
        }
    },
    mounted() {
        this.loadDistributorBookCost();
    },
    methods: {
        async loadDistributorBookCost() {
            if(!this.canLoadCost) {
                this.distributorBookCost = 0;
                return;
            }
            this.isLoading = true;
            try {
                const cost = await this.api.getPartDistributorBookCost(this.part, this.distributorCode);
                this.distributorBookCost = cost;
            } catch (error) {
                this.$toast({message: "Loading Distributor Book Cost failed unexpectedly.", type: "danger"});
            } finally {
                this.isLoading = false;
            }
        },
    },
    watch: {
        part() {
            this.loadDistributorBookCost();
        },
        distributorCode() {
            this.loadDistributorBookCost();
        },
    }
});
