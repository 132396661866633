
import { defineComponent } from "vue";
// @ts-expect-error
import _ from "lodash";
import { Tooltip } from "@progress/kendo-popups-vue-wrapper";
// @ts-expect-error
import { loading } from "aml";
import { OptionVM } from "@/interfaces";

export default defineComponent({
  name: "SalesRepField",
  components: {
    Tooltip,
    loading,
  },
  emits: ["update:modelValue"],
  props: {
    countryCode: String,
    postalCode: String,
  },
  inject: ["api"],
  data() {
    return {
      tooltipContent:
        `<div class="cost-tooltip">
          <p>Sales Rep is loaded when Country is selected. If postal code is provided, Sales Rep might be updated.</p>
          <p class="sales-rep-tooltip-note">Note! For US and Canada postal code is a mandatory field.</p>
        </div>`,
      isLoading: false,
      rep: null as null | OptionVM,
      loadSalesRepDebounced: _.debounce(this.loadSalesRep, 500) as Function,
      countryWithMandatoryPostalCode: ["USA", "CAN"],
    };
  },  
  mounted() {
    this.loadSalesRep();
  },
  methods: {
    async loadSalesRep() {
      if (!this.canLoadSalesRep()) {
        return;
      }

      this.isLoading = true;
      try {
        this.rep = await this.api.getRepForCountry(this.countryCode, this.postalCode);
        this.$emit("update:modelValue", this.rep?.code);
      } catch (error) {
        this.$toast({ message: "Loading of Sales Rep failed unexpectedly.", type: "danger" });
      } finally {
        this.isLoading = false;
      }
    },
    canLoadSalesRep(): boolean {
      if (!this.countryCode) {
        return false;
      } 

      // for US and Canada we should have postal code.
      if (this.countryWithMandatoryPostalCode.includes(this.countryCode)) {
        if (!this.postalCode) {
          // mark current selected rep as not valid.
          this.rep = null;
          this.$emit("update:modelValue", null);
          return false;
        }
      }

      return true;
    },
  },
  watch: {
    countryCode() {
      this.loadSalesRep();
    },
    postalCode() {
      this.loadSalesRepDebounced();
    },
  },
});
