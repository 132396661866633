
import { defineComponent } from "vue";
import { Tooltip } from "@progress/kendo-popups-vue-wrapper";
import { RegistrationType, RegistrationTypeName } from "@/enums";

export default defineComponent({
  name: "RegistrationTypeField",
  components: {
    Tooltip
  },
  props: {
    part: String,
    registrationTypeId: Number,
    isDesignRegistrationPart: Boolean,
  },
  data() {
    return {
      tooltipContent: "<p class=\"type-tooltip\">Registration Part not identified. Type and Part will be validated on submission.</p>",
    }
  },
  computed: {
    registrationType(): string {
      // User has selected an option, we know the type
      if (this.isDesignRegistrationPart === true)
        return RegistrationTypeName[RegistrationType.DESIGN_REGISTRATION];
      if (this.isDesignRegistrationPart === false)
        return RegistrationTypeName[RegistrationType.DEMAND_CREATION];

      // Part is empty, don't show the type
      if (!this.part) return "---";

      // Part is not empty, either show initial type or don't show anything
      return this.registrationTypeId
        ? RegistrationTypeName[this.registrationTypeId]
        : "---";
    },
    isRegistrationTypeUnknown(): boolean {
      // We don't have initial registration type and part is filled in
      return !this.registrationTypeId && this.isDesignRegistrationPart === undefined && !!this.part;
    },
  },
});
